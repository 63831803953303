@tailwind base;
@tailwind components;
@tailwind utilities;

/* Animated gradient background */
.animated-gradient-bg {
  background: linear-gradient(270deg, #BE25A0, #FFCF00, #D843CF, #9754E6, #FF0004);
  background-size: 600% 600%;
  animation: gradientAnimation 12s ease infinite;
}

/* Keyframes for gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Custom global styles */
html, body {
  font-family: Arial, sans-serif;
  padding: 0;
  margin: 0;
  width: 100%; /* Ensure full width */
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: #f9f9f9;
  line-height: 1.6;
  scroll-behavior: smooth; /* Smooth scrolling for better UX */
}

body {
  padding-top: 10px; /* Adjust based on your navbar height, to prevent content from hiding behind the sticky navbar */
}

header {
  position: -webkit-sticky; /* For Safari support */
  position: sticky;
  top: 0;
  z-index: 50; /* Ensures the navbar stays on top of other content */
  background-color: #f5f5f5; /* Background color to ensure visibility */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow to separate navbar from content */
}

h1 {
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #0070f3;
  text-decoration: none;
}

